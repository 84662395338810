import React from 'react';
import { graphql } from 'gatsby';

import Seo from 'components/container/Seo';
import SectionHero from 'components/container/SectionHero';
import PageBuilder from 'components/container/PageBuilder';

export default function TemplateHome({ data, pageContext }) {
  const url = pageContext?.url;
  const heroBackground = data?.heroBackground;
  const imageTextBackground = data?.imageTextBackground;
  const seoTitle = data?.seoTitle;
  const seoDescription = data?.seoDescription;
  const seoImage = data?.seoImage;
  const socialsBackground = data?.socialsBackground;

  const { pageBuilderNeo } = data?.craft?.entry;

  return (
    <>
      <Seo
        title={seoTitle}
        description={seoDescription}
        image={seoImage?.[0]?.socialMediaUrl}
        url={url}
      />
      <SectionHero
        backgroundImage={heroBackground}
        heroTitleImageAlt="Gebraut für Deine Momente!"
      />

      <PageBuilder
        components={pageBuilderNeo}
        backgroundImage={imageTextBackground}
        backgroundImages={[imageTextBackground, socialsBackground]}
      />
    </>
  );
}

export const query = graphql`
  query QueryHome($id: [Int]!) {
    craft {
      # entry for PageBuilder ImageText
      entry(id: $id) {
        ... on Craft_Home {
          title
          seoTitle
          seoDescription
          seoImage {
            socialMediaUrl: url(transform: transformSocialMedia)
          }
          pageBuilderNeo {
            ...PageBuilderQuery
          }
        }
      }
    }

    heroBackground: file(
      relativePath: { eq: "hero/20230302_Kampagne_Hero_V6.jpg" }
    ) {
      ...imageDefault
    }

    socialsBackground: file(relativePath: { eq: "bg-2.jpg" }) {
      ...imageDefault
    }

    socialsInstagramBackground: file(
      relativePath: { eq: "warsteiner-socials-instagram-background.jpg" }
    ) {
      ...imageDefault
    }

    socialsYoutubeBackground: file(
      relativePath: { eq: "warsteiner-socials-youtube-background.png" }
    ) {
      ...imageDefault
    }

    imageTextBackground: file(
      relativePath: { eq: "warsteiner-stage-content-bg.jpg" }
    ) {
      ...imageDefault
    }

    undercoverBossImage: file(
      relativePath: { eq: "Kampagnen/undercover-boss.jpg" }
    ) {
      ...imageDefault
    }
  }
`;
