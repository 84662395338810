import React from 'react';
import PropTypes from 'prop-types';
import scrollToElement from 'scroll-to-element';

import Headline from 'components/common/Headline';
import Icon from 'components/common/Icon';

import {
  ScrollIndicatorStyled,
  ScrollIndicatorIconWrapStyled,
} from './ScrollIndicatorStyled';

const ScrollIndicator = ({ className, text, targetElement }) => {
  if (!targetElement) {
    return null;
  }

  return (
    <ScrollIndicatorStyled
      className={className}
      onClick={() => {
        scrollToElement(targetElement, {
          offset: 0,
          duration: 400,
        });
      }}
    >
      {text && <Headline element="h4">{text}</Headline>}
      <ScrollIndicatorIconWrapStyled>
        <Icon type="arrowMoreDown" />
      </ScrollIndicatorIconWrapStyled>
    </ScrollIndicatorStyled>
  );
};

ScrollIndicator.propTypes = {
  targetElement: PropTypes.string.isRequired,
};

export default ScrollIndicator;
