import React, { useRef, useEffect, useReducer, useContext } from 'react';
import { withPrefix } from 'gatsby';
import PropTypes from 'prop-types';
import { PoseGroup } from 'react-pose';

import { useScrolledIntoView } from 'utils/hooks/useScrolledIntoView';
import { useSessionStorage } from 'utils/hooks/useSessionStorage';
import media from 'utils/mediaqueries';

import { AgegateContext } from 'components/container/Agegate';
import { CookiegateContext } from 'components/container/Cookiegate';
import Link from 'components/common/Link';

import {
  PosedTarget,
  SlideInSlowPosed,
  SlideInSlowChildPosed,
} from 'components/common/Posed';

import { VideoWrapperPosed } from './SectionHeroPosed';

import {
  VideoWrapperStyled,
  VideoStyled,
  OverlayGradientsStyled,
  SectionHeroStyled,
  SectionHeroHeadlinesStyled,
  SectionHeroHeadlineGoldFirstStyled,
  SectionHeroHeadlineGoldSecondStyled,
  SectionHeroHeadlineGoldThirdStyled,
  SectionHeroHeadlineGoldForthStyled,
  SectionHeroHeadlineGoldFifthStyled,
  SectionHeroBackgroundImageStyled,
  SectionHeroScrollIndicatorStyled,
  SectionHeroScrollIndicatorTargetStyled,
} from './SectionHeroStyled';

const TIMEUPDATE_BEGIN = 'TIMEUPDATE_BEGIN';
const TIMEUPDATE_MID = 'TIMEUPDATE_MID';
const TIMEUPDATE_END = 'TIMEUPDATE_END';
const HAS_VIDEO_PLAYED = 'HAS_VIDEO_PLAYED';

export default function SectionHero({ backgroundImage }) {
  const [hasVideoPlayed, setHasVideoPlayed] = useSessionStorage(
    HAS_VIDEO_PLAYED,
    false,
  );

  // Check for video time updates.
  const videoRef = useRef(null);
  const sourceRef = useRef(null);

  const { confirmedAge } = useContext(AgegateContext);
  const { acceptedCookies } = useContext(CookiegateContext);

  // Play video after agegate button is pressed
  if (
    acceptedCookies &&
    confirmedAge &&
    sourceRef?.current &&
    videoRef?.current
  ) {
    videoRef.current.play();
  }

  useEffect(() => {
    if (sourceRef.current && videoRef.current) {
      let src = withPrefix('/videos/230228_Warsteiner_HD_Web_Cut_1920x1080.mp4');
      if (
        window.matchMedia(
          media('mobile', 'tabletFluid', 'portrait', { includeAtRule: false }),
        ).matches
      ) {
        src = withPrefix('/videos/230228_Warsteiner_HD_Web_Cut_720x1280.mp4');
      }
      if (
        window.matchMedia(media('laptop', 0, null, { includeAtRule: false }))
          .matches
      ) {
        src = withPrefix('/videos/230228_Warsteiner_HD_Web_Cut_1920x1080.mp4');
      }
      sourceRef.current.src = src;
      videoRef.current.load();
    }
  }, [sourceRef, videoRef]);

  const [keyframes, dispatch] = useReducer(
    (state, { type }) => {
      switch (type) {
        case TIMEUPDATE_BEGIN:
          return { ...state, begin: true };
        case TIMEUPDATE_MID:
          return { ...state, mid: true };
        case TIMEUPDATE_END:
          return { ...state, end: true };
        case HAS_VIDEO_PLAYED:
          return {
            ...state,
            hasPlayed: true,
          };

        default:
          throw new Error('Unknown Action type');
      }
    },
    {
      begin: false,
      mid: false,
      end: false,
      hasPlayed: hasVideoPlayed,
    },
  );

  useEffect(() => {
    function handleTimeupdate() {
      if (videoRef.current) {
        const { currentTime, duration } = videoRef.current;
        const playPos = currentTime / duration;

        if ( duration != undefined ) {
          console.log( duration - currentTime, duration )
        }

        if (!keyframes.begin && playPos >= 0.2) {
          dispatch({ type: TIMEUPDATE_BEGIN });
        }
        if (!keyframes.mid && playPos >= 0.6) {
          dispatch({ type: TIMEUPDATE_MID });
        }
        if (!keyframes.end && duration - currentTime <= 0.6) {
          console.log( 'dispatch' )
          dispatch({ type: TIMEUPDATE_END });
        }
        if (duration === currentTime) {
          setHasVideoPlayed(true);
          dispatch({ type: HAS_VIDEO_PLAYED });
        }
      }
    }
    if (videoRef.current) {
      videoRef.current.addEventListener('timeupdate', handleTimeupdate);
      return () =>
        videoRef.current?.removeEventListener('timeupdate', handleTimeupdate);
    }
  }, [keyframes, dispatch, videoRef]);

  const scrollCheckTarget = useRef(null);
  const [hasScrolledIntoView] = useScrolledIntoView({
    target: scrollCheckTarget,
    threshold: 0,
    rootMargin: '-5px',
    retrigger: false,
  });

  return (
    <PosedTarget ref={scrollCheckTarget}>
      <SectionHeroStyled>
        <SectionHeroBackgroundImageStyled src={backgroundImage} />
        <SlideInSlowPosed
          pose={
            acceptedCookies &&
            confirmedAge &&
            (keyframes.end || (hasVideoPlayed && hasScrolledIntoView)
              ? 'shown'
              : 'hidden')
          }
        >
          <SectionHeroHeadlinesStyled>
            <SlideInSlowChildPosed>
              <SectionHeroHeadlineGoldFirstStyled element="h1">
                Gebraut für
              </SectionHeroHeadlineGoldFirstStyled>
            </SlideInSlowChildPosed>
            <SlideInSlowChildPosed>
              <SectionHeroHeadlineGoldSecondStyled element="h1">
                Deine Momente!
              </SectionHeroHeadlineGoldSecondStyled>
            </SlideInSlowChildPosed>
            <SlideInSlowChildPosed>
              <SectionHeroHeadlineGoldThirdStyled element="h1">
              </SectionHeroHeadlineGoldThirdStyled>
            </SlideInSlowChildPosed>
            <SlideInSlowChildPosed>
              <SectionHeroHeadlineGoldForthStyled element="h1">
              </SectionHeroHeadlineGoldForthStyled>
            </SlideInSlowChildPosed>
            <SlideInSlowChildPosed>
              <SectionHeroHeadlineGoldFifthStyled element="h1">
              </SectionHeroHeadlineGoldFifthStyled>
            </SlideInSlowChildPosed>
          </SectionHeroHeadlinesStyled>
        </SlideInSlowPosed>

        <PoseGroup flipMove={false}>
          {keyframes.end || keyframes.hasPlayed ? null : (
            <VideoWrapperPosed key="video">
              <VideoWrapperStyled>
                <VideoStyled
                  autoPlay={acceptedCookies && confirmedAge}
                  muted
                  playsInline
                  ref={videoRef}
                >
                  <source ref={sourceRef} type="video/mp4" />
                </VideoStyled>
              </VideoWrapperStyled>
            </VideoWrapperPosed>
          )}
        </PoseGroup>

        <OverlayGradientsStyled
          show={
            acceptedCookies &&
            confirmedAge &&
            1 != 1 &&
            (keyframes.end || (hasVideoPlayed && hasScrolledIntoView))
          }
        />

        <SectionHeroScrollIndicatorStyled
          text="Mehr erfahren"
          targetElement="#hero-scroll-target"
          show={
            acceptedCookies &&
            confirmedAge &&
            (keyframes.end || (hasVideoPlayed && hasScrolledIntoView))
          }
        />
      </SectionHeroStyled>

      <SectionHeroScrollIndicatorTargetStyled id="hero-scroll-target" />
    </PosedTarget>
  );
}

SectionHero.propTypes = {
  backgroundImage: PropTypes.object.isRequired,
};
