import styled from 'utils/emotion';
import media from 'utils/mediaqueries';

import HeadlineGold from 'components/common/HeadlineGold';
import ScrollIndicator from 'components/common/ScrollIndicator';
import Image from 'components/common/Image';
import OverlayGradients from 'components/common/OverlayGradients';

export const SectionHeroStyled = styled('div')({
  position: 'relative',
  height: '100vh', // fallback

  '@supports(height: calc(100vh - var(--vh-offset, 0px)))': {
    height: 'calc(100vh - var(--vh-offset, 0px))',
  },

  img: { objectPosition: '38% center !important' },

  [media('tabletFluid')]: {
    img: { objectPosition: 'center center !important' },
  },
});

export const VideoWrapperStyled = styled('div')({
  position: 'absolute',
  height: '100vh',
  width: '100%',
  top: 0,
  left: 0,
  overflow: 'hidden',
});

export const VideoStyled = styled('video')({
  left: '50%',
  minHeight: '100%',
  minWidth: '100%',
  position: 'absolute',
  top: '50%',
  transform: 'translate(-50%, -50%)',
});

export const OverlayGradientsStyled = styled(OverlayGradients)(
  {
    transition: 'opacity .75s ease-in-out',
  },
  (props) => ({
    opacity: props.show ? 0.8 : 0,
  }),
);

export const SectionHeroBackgroundImageStyled = styled(Image)({
  height: '100%',
});

export const SectionHeroHeadlinesStyled = styled('div')({
  position: 'absolute',
  top: '30%',
  right: '-5%',
  width: 220,
  height: 240,

  [media('tablet')]: {
    top: '36%',
    right: '10%',
    width: 400,
    height: 360,
  },

  [media('tablet', 0, 'landscape')]: {
    top: '15%',
    right: '0%',
  },

  [media('laptop')]: {
    top: '30%',
    right: '20%',
    width: 360,
    height: 360,
  },
  [media('desktop')]: {
    top: '50%',
    transform: 'translateY(-50%)',
    width: 570,
    height: 480,
  },
});

export const SectionHeroHeadlineGoldFirstStyled = styled(HeadlineGold)({
  margin: '0 0 0 0px',
  lineHeight: 1,
  [media('tabletFluid')]: {
    margin: '0',
    textAlign: 'left',
    width: '100%',
  }
});

export const SectionHeroHeadlineGoldSecondStyled = styled(HeadlineGold)({
  fontWeight: '700',
  textAlign: 'left',
  width: '100%',
});

export const SectionHeroHeadlineGoldThirdStyled = styled(HeadlineGold)({
  margin: '0 0 0 24px',
  lineHeight: 1,
  [media('tabletFluid')]: {
    margin: '0 0 0 72px',
  },
  fontWeight: '700'
});

export const SectionHeroHeadlineGoldForthStyled = styled(HeadlineGold)({
  margin: '0 0 0 12px',
  lineHeight: 1,
  [media('tabletFluid')]: {
    margin: '0 0 0 48px',
  }
});

export const SectionHeroHeadlineGoldFifthStyled = styled(HeadlineGold)({
  margin: '0 0 0 24px',
  lineHeight: 1,
  [media('tabletFluid')]: {
    margin: '0 0 0 96px',
  }
});

export const SectionHeroTitleImageStyled = styled(Image)({
  transition: 'none',
  width: '100%',
  height: 'auto',
});

export const SectionHeroScrollIndicatorStyled = styled(ScrollIndicator)(
  {
    position: 'absolute',
    bottom: '10%',
    left: '50%',
    transition:
      'opacity .5s cubic-bezier(0.49, 0.32, 0.23, 0.96), transform .5s cubic-bezier(0.34, 0.5, 0.24, 1.11)',
  },
  (props) => ({
    opacity: props.show ? 1 : 0,
    transform: props.show ? 'translate(-50%, 0)' : 'translate(-50%, 20px)',
  }),
);

export const SectionHeroScrollIndicatorTargetStyled = styled('div')({
  visibility: 'hidden',
  position: 'absolute',
  width: '100%',
  height: '0px',
});
